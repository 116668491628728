import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

import cedulasApi from '../api/cedulasApi'
import { Navbar } from '../components/Navbar'
import NL from '../assets/imgs/nuevo_leon.png'
import Economia from '../assets/imgs/economia.png'
import LogoImpulso from '../assets/imgs/IMPULSO.png'
import '../assets/css/date-details.css'
import { DateReport } from '../components/DateReport';
import dateApi from '../api/dateApi';


export const DateInfo = () => {
    const [data, setData] = useState({})
    const [links, setLinks] = useState([])

    const {cedula} = useParams()
    console.log(cedula)
    const getData = async()=> {
        try {
            const {data} =await cedulasApi.get('/'+cedula)
            console.log(data)
            if (data && !data.error) {
                setData(data)
                console.log(data)                
            }
        } catch (error) {
            
        }
    }
    const getLinks = async()=>{
        const {data} = await dateApi.get('/links');
        console.log(data.links)
        if (data && !data.error) {
          setLinks(data.links)
        }
    }
   
    useEffect(() => {
        if (cedula) getData();getLinks();
    }, [])
    

  return (
    <>
        <Navbar/>
        <div className="status">
            <div className="info">
                <h4>Estado</h4><p> {data.completed == 0? "Sin evaluar" : "Completada"  }</p> 
            </div>
            <div className="info">
                {
                    data.completed === 1 && (<>
                    
                        <h4>Solicitud:</h4><p > {data.approved == 1? "Aprobada" : "Declinada"  }</p> <div className={`dot ${data.approved == 1? "green" : "red"  }`}></div>
                    </>)
                }
            </div>
        </div>
        <div className="details-container pdf">

            <div className="details-header">
                <img src={NL} alt="Estado de Nuevo Leon" />
                <img src={LogoImpulso} alt="Impulso Nuevo Leon" />
                <img src={Economia} alt="Secretaria de economia" style={{alignSelf: 'center',width: '120px'}} />
                
            </div>
            <div className="details-title" style={{ color: 'var(--azul)', textAlign: 'center'}}>
                {
                  data['id_date'] && data['date.mode'] == 'virtual' ? 
                  <h2>CONFIRMACIÓN DE CITA VIRTUAL</h2>
                  :
                  data['no_date']  ? 
                  <h2>CONFIRMACIÓN DE SOLICITUD SIN CITA</h2>
                  :
                  <h2>CONFIRMACIÓN DE CITA PRESENCIAL</h2>

                }
                <h3>PROGRAMA IMPULSO NUEVO LEÓN 2024</h3>
            </div>
            {
                !data.no_date && 
                <div className="details-header" style={{justifyContent: 'start'}}>
                    <div className="date">
                        <p> Fecha: {data["date.date"] }  </p>
                        <p> Hora:  {data["date.time"] }  </p>
                    </div>
                    
                </div>

            }
            <hr />
        <div className="form">
            <p>Cedula: <b style={{color: 'red'}}>{data.cedula }</b>  </p>
            <p>Empresa / Solicitante: {data["form.NOMBRE_EMPRESA"] }  </p>
            <p>Representante legal: {data["form.REPRESENTANTE_LEGAL"] }  </p>
            <p> {data["form.CORREO_ELECTRÓNICO"] }  </p>
            <p>RFC: {data["form.RFC"] }  </p>
            
        </div>
        {
            data['id_date'] && data['date.mode'] == 'virtual' ?
            <div className="place" style={{paddingBottom:'5rem'}}>
                <p> Reunión virtual:</p> <a href="" target= {links.length!=0 && links.find(link => link.id_link === data['date.id_link']).link}> {links.length!=0 && links.find(link => link.id_link === data['date.id_link']).link}</a>  
            </div>
                :
            !data.no_date?
            <div className="place">
                <div className="place-address">

                    <p>
                        Lugar de la cita:
                    </p>
                    <address>
                        Pabellón Ciudadano, Tercer Piso.
                        Washington 2000 Ote. C.P. 64010,
                        Col. Obrera, Monterrey, N. L. .-
                    </address>

                </div>
                <div className="recomendations">
                    <ul>
                        <li>El trámite es personal y debe ser realizado por apoderado o representante legal de la empresa</li>
                        <li> Acudir a la cita con Identificación oficial vigente y la confirmación de cita.</li>
                        <li>Presentarse con cubrebocas con al menos 10 min de antelación a su cita programada. Se agradece puntualidad. </li>
                        <li>Atender las disposiciones de aforo y medidas sanitarias del recinto. </li>
                        <li>Contamos con Estacionamientos Públicos ubicados frente al Pabellón Ciudadano, entra E10; costo por hora $25 pesos. </li>
                    </ul>
                    <br/>
                    <h4 style={{color:' var(--azul)', textAlign: 'center'}}>El trámite para emitir ésta cédula de validación es TOTALMENTE GRATUITO.</h4>
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3595.858278365746!2d-100.29401688498083!3d25.675992283675782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662bfd86cdf6d0b%3A0x7d930941e57f3d7f!2sPabell%C3%B3n%20Ciudadano!5e0!3m2!1ses-419!2smx!4v1642821512919!5m2!1ses-419!2smx" width="250" height="250" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>
            </div>
            :
            <></>
            
        }
        </div>
        <div id="editor"></div>
        

        <div className="actions">
           {
            data && data["date.time"]  && links.length != 0 &&
            <PDFDownloadLink fileName={data.cedula} className='btn btn-primary get-pdf' style={{position:'fixed', bottom:'2rem', right:'2rem'}}  document={<DateReport data={data} links={links}/>} >Descargar PDF </PDFDownloadLink>
           }

        </div>
        
            <div className="actions">
                {/* <p>Para cambiar, cancelar o administrar la cita, favor de ponerse en contacto con nosotros</p>
                <a href={`/form-data/editar/${data["form.id_form"]} `} className="btn btn-secundary">
                    Editar datos
                </a>
                <a href="/files/editar/{data["form.id_form"] }" className="btn btn-secundary">
                    Editar archivos
                </a> 
                <a href="/dates/editar/{data["form.id_form"] }/{data["id_date"] }" className="btn btn-secundary">
                    Editar cita
                </a>
                <button className="btn btn-secundary cancel-date" data-path="/dates/{data["id_date"] }/{data["form.id_form"] }">
                    Cancelar cita
                </button> */}
            </div>
    </>
  )
}
