import { Button } from 'components/form/Button';
import { FormField } from 'components/form/FormField';
import { Loading } from 'components/Loading';
import { RegisterValues } from 'pages/Login';
import React from 'react'

export const RegisterForm = ({ registerForm, handleChangeRegister, handleRegisterSubmit, loading, msg }: { registerForm: RegisterValues; handleChangeRegister: (e: { target: HTMLInputElement }) => void; handleRegisterSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>; loading: boolean; msg: any}) => (
    <form className="flex flex-col" onSubmit={handleRegisterSubmit}>
      <FormField label="Nombre" name="name" onChange={handleChangeRegister} required type="text" value={registerForm.name} />
      <FormField label="Correo" name="email" onChange={handleChangeRegister} required type="email" value={registerForm.email} />
      <FormField label="Contraseña" name="password" onChange={handleChangeRegister} required type="password" value={registerForm.password} />
      <div className="form-field-line btn btn-secundary">
        <input type="checkbox" name="tac" id="tac" onChange={handleChangeRegister} checked={registerForm.tac} required />
        <label className="tacBtn" htmlFor="tac">
          Acepto términos y condiciones
        </label>
      </div>
      <p className='text-red-400'>{msg}</p>
      {loading ? <Loading /> : <Button onClick={handleRegisterSubmit} styles="" disabled={loading} text="Registrarse" type="submit" />}
    </form>
  );
