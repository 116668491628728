import React from 'react'
import Cierre from '../assets/imgs/Cierre.jpeg'
import Cierre2 from '../assets/imgs/Cierre2.jpeg'
import { Navbar } from '../components/Navbar'

export const CloseProgram = () => {
  return (
    <>
        <Navbar />
        <div className="close-program" style={{display:'flex', justifyContent:'center', flexDirection:'column', padding:'1rem'}}>
         <b>Cerramos convocatoria, ¡gracias!</b> 
         <p>El registro digital para la convocatoria de nuestro programa ha concluido.</p>
        <br />
        <p>
          Agradecemos el interés de empresarios como tú. Te invitamos a seguir nuestras redes sociales y página oficial de la Secretaría de Economía estatal para estar al tanto de futuros programas y lanzamientos.
        </p>
        <a href="/" className='btn-secundary'>Regresar</a>
        </div>

    </>
  )
}



    
    
