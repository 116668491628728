import React from 'react'

export const FormField = ({label, onChange, value, type, required, name  }) => {
  return (
    <div className='flex flex-col '>
      <label className='text-lg' htmlFor={name}>{label}</label>
      <input className='rounded-md border-x-gray-300'  type={type} name={name} id={name} value={value} onChange={onChange} required={required} />
    </div>
  )
}
