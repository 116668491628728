import { AnimatePresence, motion } from "motion/react";
import  { useEffect, useState } from "react";
import { PortableText } from "@portabletext/react";
import statusApi from "api/statusApi";


export const Alert = () => {
  const [alert, setAlert] = useState<any>(null);

  const getAlert = async () => {
    try {
      const res =await statusApi.get('/alerts');
      if (res && res.data) {
        console.log(res)
        setAlert(res.data.data);
      }
      
    } catch (error) {
      
    }
  };
  useEffect(() => {
    getAlert();
  }, []);

  const [isVisible, setIsVisible] = useState(true);
  return isVisible && alert && alert.isActive ? (
    <AnimatePresence>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div
          className="absolute inset-0 bg-black opacity-50"
          onClick={() => setIsVisible(false)}
        ></div>
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="bg-white max-w-[800px] p-4 z-50 w-[95vw] relative rounded-lg shadow-lg"
        >
          <button
            className="absolute top-4 right-4 cursor-pointer"
            onClick={() => setIsVisible(false)}
          >
            Cerrar
          </button>
          <h1 className="text-xl text-red-400 text-center font-semibold mb-2">
            ¡Atención!
          </h1>
          <PortableText value={alert.text} />
        </motion.div>
      </div>
    </AnimatePresence>
  ) : null;
};
