import Swal from "sweetalert2";

export const validateRFC = (RFC,rfcs ) => {
    const regex = /^([A-ZÑ&]{3,4})?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    const rfc = RFC.toUpperCase();
    if (!regex.test(rfc)) {
        console.log("Formato incorrecto")
        return {
            error: true,
            msg: "Formato Inconrrecto"
        }
    }else if( rfcs.includes(rfc) ){
        console.log(rfc)
    
        Swal.fire({
            title: "RFC EN USO",
            text: "EL RFC QUE INTENTAS REGISTRAR CUENTAS CON UN REGISTRO PREVIAMENTE EMITIDO POR LO QUE NO SE PUEDE CONTINUAR CON EL TRÁMITE.",
            icon: "error",
            footer: '¿Tienes dudas? <a href="/contacto">Ponte en contacto con nosostros</a>',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            confirmButtonText:
                    '<a class="btn" href="/">Entendido</a>',
            cancelButtonText:
                    'Elegir otro RFC',
            cancelButtonAriaLabel: 'Continuar',
            showConfirmButton: true,
          })
          return {
            error: true,
            msg: "RFC en uso"
            }
    }else{
        return {
            error: false,
        }
    }
    
}

            