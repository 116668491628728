import { Header } from "../components/Header";
import Swal from "sweetalert2";

import "../assets/css/auth.css";
import { useAuth } from "../hooks/useAuth";
import LogoImpulso from "../assets/imgs/IMPULSO.png";
// import Cierre from '../assets/imgs/Cierre.jpeg'
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import authApi from "../api/authApi";
import { AxiosError } from "axios";
import { Loading } from "../components/Loading";
import { FormField } from "../components/form/FormField";
import { Button } from "../components/form/Button";
import { Alert } from "../components/Alert";
import { LoginForm } from "components/Auth/LoginForm";
import { RegisterForm } from "components/Auth/Register";

export type LoginValues = {
  email: string;
  password: string;
};
export type RegisterValues = {
  name: string;
  email: string;
  password: string;
  tac: boolean;
};
type ErrorsType = {
  location: string;
  msg: string;
  param: string;
};
const initialLoginForm = { email: "", password: "" };
const initialRegisterForm = { email: "", name: "", password: "", tac: false };
export const Login = () => {
  const [showRegister, setShowRegister] = useState<boolean>(false);
  const [loginForm, setLoginForm] = useState<LoginValues>(initialLoginForm);
  const [msg, setMsg] = useState("");
  const [registerForm, setRegisterForm] =
    useState<RegisterValues>(initialRegisterForm);
  const { login, register } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { token } = useParams();

  const activeToken = async (token: string) => {
    try {
      const { data } = await authApi.post("/" + token);

      if (data && !data.error) {
        Swal.fire("Listo", data.msg, "success");
        return data; // Devuelve los datos si no hay error
      }
    } catch (error) {
      const err = error as AxiosError;

      if (err.response) {
        // Maneja errores de respuesta del servidor
        return err.response.data;
      } else {
        // Maneja errores de conexión
        return {
          error: true,
          msg: "Servidor desconectado",
        };
      }
    }
  };

  useEffect(() => {
    if (!token) return;

    activeToken(token);
  }, [token]);

  const handleChangeLogin = (e: { target: HTMLInputElement }) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };
  const handleLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (!loginForm.email || !loginForm.password) {
      setMsg("Faltan campos por completar");
      setLoading(false);
      return;
    }

    try {
      const res = await login(loginForm.email, loginForm.password);

      if (res && !res.error) {
        if (location.state) {
          navigate(`${location.state.location.pathname}`);
        } else {
          navigate("/");
        }
      } else {
        if (res.errors) {
          const errors = res.errors.map(
            (error: ErrorsType) => `<li>${error.msg}</li>`
          );
          Swal.fire({
            title: "Error",
            icon: "error",
            html: `<ul style="list-style: none;">${errors}</ul>`,
          });
        } else {
          Swal.fire("Error", res.msg, "error");
          setMsg(res.msg);
        }
      }
    } catch (error) {
      console.error("Error during login:", error);
      Swal.fire(
        "Error",
        "Ocurrió un error durante el inicio de sesión",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChangeRegister = (e: { target: HTMLInputElement }) => {
    if (e.target.type === "checkbox")
      setRegisterForm({ ...registerForm, [e.target.name]: e.target.checked });
    else setRegisterForm({ ...registerForm, [e.target.name]: e.target.value });
  };

  const handleRegisterSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(registerForm);

    if (!registerForm.tac || !registerForm.email || !registerForm.password) {
      setMsg(registerForm.tac ? "Faltan campos por completar" : "Es necesario aceptar los términos y condiciones");
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const res = await register(
        registerForm.email,
        registerForm.password,
        registerForm.name
      );

      if (res && !res.error) {
        Swal.fire("Listo", res.msg, "success");
        setShowRegister(false);
        setRegisterForm(initialRegisterForm);
      } else {
        if (res.errors) {
          const errors = res.errors.map(
            (error: ErrorsType) => `<li>${error.msg}</li>`
          );
          Swal.fire({
            title: "Error",
            icon: "error",
            html: `<ul style="list-style: none;">${errors}</ul>`,
          });
        } else {
          Swal.fire("Error", res.msg, "error");
          setMsg(res.msg);
        }
      }
    } catch (error) {
      console.error("Error during registration:", error);
      Swal.fire("Error", "Ocurrió un error durante el registro", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Alert />

      <Header />
      <div>
        <main>
          <div className="login-form rounded-md shadow-lg p-5 flex flex-col bg-gray-50">
            <img className="w-28 self-center" src={LogoImpulso} alt="IMPULSO" />
            <div className="mt-5 mb-3">
              <h1 className="font-bold text-lg">
                {showRegister ? "REGISTRO" : "INICIAR SESION"}
              </h1>
            </div>
            {!showRegister ? (
              <LoginForm
                handleChangeLogin={handleChangeLogin}
                handleLoginSubmit={handleLoginSubmit}
                loginForm={loginForm}
                loading={loading}
              />
            ) : (
              <RegisterForm
                registerForm={registerForm}
                handleChangeRegister={handleChangeRegister}
                handleRegisterSubmit={handleRegisterSubmit}
                loading={loading}
                msg={msg}
              />
            )}

            <div className="form-field">
              <button
                onClick={() => setShowRegister(showRegister ? false : true)}
                className=" btn self-end p-0 text-blue-400"
              >
                <u>
                  {showRegister
                    ? "Iniciar sesión"
                    : "No tengo una cuenta, registrarme"}
                </u>{" "}
              </button>
            </div>
          </div>
        </main>

        {/* <section className="search-date">
            <div className="search-date-title">
                <h3>Buscar cita</h3>
                <form action="/dates/cedula" method="post" className="form-field search-date">
                    <label htmlFor="id_date">Cedula</label>
                    <input type="text" name="id_date" id="id_date" required  data-oninvalid="this.setCustomValidity('Ingrese un formato válido')"   className="registro" />
                    <button className="btn btn-primary">
                        <i className="fas fa-search"></i>
                    </button>
                </form>
            </div>
        </section> */}
      </div>
    </>
  );
};
