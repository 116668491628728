import { Button } from 'components/form/Button';
import { FormField } from 'components/form/FormField';
import { Loading } from 'components/Loading';
import { LoginValues } from 'pages/Login';
import { Link } from "react-router-dom";

export const LoginForm = ({ loginForm, handleChangeLogin, handleLoginSubmit, loading }: { loginForm: LoginValues; handleChangeLogin: (e: {
  target: HTMLInputElement;
}) => void; handleLoginSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>; loading: boolean; }) => (
  <form className="flex flex-col" onSubmit={handleLoginSubmit}>
    <FormField label="Correo" name="email" onChange={handleChangeLogin} required type="email" value={loginForm.email} />
    <FormField label="Contraseña" name="password" onChange={handleChangeLogin} required type="password" value={loginForm.password} />
    <div className="form-field">
      <Link to="/contraseña" state={{ email: loginForm.email }} className="btn-secundary btn reset-btn">
        ¿Olvidaste tu contraseña?
      </Link>
    </div>
    {loading ? <Loading /> : <Button  onClick={handleLoginSubmit} styles="" disabled={loading} text="Entrar" type="submit" />}
  </form>
);