import axios from 'axios';
import { path } from './env';


const  baseURL = path + '/auth';


const authApi = axios.create({baseURL})

authApi.interceptors.request.use(
    async(config)=>{
        const token =  localStorage.getItem('impulso-token')
        if (token) {
            config.headers['x-token'] =token
        }
        return config
    }
)


export default authApi; 