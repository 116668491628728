export function validateForm (name, val){
    const spanError = document.querySelector(`#error-${name}`)
    
    switch (name) {
        case 'GÉNERO_PERSONA':
            const generos = ["FEMENINO", "MASCULINO", "PREFIERO NO RESPONDER"]
            if ( !generos.includes(val.toUpperCase()) ) {
                spanError.textContent = "Genero solo puede ser FEMENINO, MASCULINO, PREFIERO NO RESPONDER"
                return false
            }
            spanError.textContent = "";
            return true
        case 'RFC':
            const regex = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
            if (!regex.test(val.toUpperCase())) {
                spanError.textContent = "Formato incorrecto"
                return false
            }
            spanError.textContent = "";
            return true
            
        case 'RÉGIMEN':
            const regimen = ["PERSONA MORAL", "PERSONA FÍSICA CON ACTIVIDAD EMPRESARIAL", "RÉGIMEN DE INCORPORACIÓN FISCAL (RIF) O RÉGIMEN SIMPLIFICADO DE CONFIANZA (RESICO)"]
            if ( !regimen.includes(val.toUpperCase()) ) {
                spanError.textContent = "Régimen solo puede ser: PERSONA MORAL, PERSONA FÍSICA CON ACTIVIDAD EMPRESARIAL, RÉGIMEN DE INCORPORACIÓN FISCAL (RIF) O RÉGIMEN SIMPLIFICADO DE CONFIANZA (RESICO)"
                return false
            }
            spanError.textContent = "";
            return true
        case 'MUNICIPIO':
            const municipio = ["ABASOLO", "AGUALEGUAS","LOS ALDAMAS","ALLENDE","ANÁHUAC","APODACA","ARAMBERRI","BUSTAMANTE","CADEREYTA JIMÉNEZ","CARMEN","CERRALVO","CIÉNEGA DE FLORES","CHINA","DOCTOR ARROYO","DOCTOR COSS","DOCTOR GONZÁLEZ","GALEANA","GARCÍA","SAN PEDRO GARZA GARCÍA","GENERAL BRAVO","GENERAL ESCOBEDO","GENERAL TERÁN","GENERAL TREVIÑO","GENERAL ZARAGOZA","GENERAL ZUAZUA","GUADALUPE","LOS HERRERAS","HIGUERAS","HUALAHUISES","ITURBIDE","JUÁREZ","LAMPAZOS DE NARANJO","LINARES","MARÍN","MELCHOR OCAMPO","MIER Y NORIEGA","MINA","MONTEMORELOS","MONTERREY","PARÁS","PESQUERÍA","LOS RAMONES","RAYONES","SABINAS HIDALGO","SALINAS VICTORIA","SAN NICOLÁS DE LOS GARZA","HIDALGO","HIDALGO","SANTA CATARINA","SANTIAGO","VALLECILLO","VILLALDAMA"]
            if ( !municipio.includes(val.toUpperCase()) ) {
                spanError.textContent = "Municipio invalido, incluye acentos"
                return false
            }
            spanError.textContent = "";
            return true
        case 'CÓDIGO_POSTAL':
            if ( val.trim().length !== 5 ) {
                spanError.textContent = "Codigo postal debe contener 5 digitos"
                return false
            }
            spanError.textContent = "";
            return true
        case 'TELÉFONO_CONTACTO':
            if ( val.trim().length !== 10 ) {
                spanError.textContent = "Telefono a 10 digitos"
                return false
            }
            spanError.textContent = "";
            return true
        case 'TELÉFONO_ALTERNO':
            if ( val.trim().length !== 10 ) {
                spanError.textContent = "Telefono a 10 digitos"
                return false
            }
            spanError.textContent = "";
            return true
        case 'DESTINO_CRÉDITO':
            const destino = ["CAPITAL DE TRABAJO", "EQUIPAMIENTO"]
            if ( !destino.includes(val.toUpperCase()) ) {
                spanError.textContent = "Solo 'CAPITAL DE TRABAJO' o 'EQUIPAMIENTO'"
                return false
            }
            spanError.textContent = "";
            return true
        case 'LOCAL':
            const local = ["RENTADO","PROPIO","PRESTADO"]
            if ( !local.includes(val.toUpperCase()) ) {
                spanError.textContent = "Solo RENTADO, PROPIO o PRESTADO"
                return false
            }
            spanError.textContent = "";
            return true
        case 'NECESIDADES_FINANCIERAS':
            
            if ( val.length > 300 ) {
                spanError.textContent = "Maximo 300 caracteres"
                return false
            }
            spanError.textContent = "";
            return true
        case 'BANCO':
            const banco = ["AFIRME","BANBAJIO","BANORTE","BANREGIO","BBVA","CITIBANAMEX","HSBC","MIFEL","MULTIVA","SANTANDER"]
            if ( !banco.includes(val.toUpperCase()) ) {
                spanError.textContent = "Banco no válido"
                return false
            }
            spanError.textContent = "";
            return true
        case 'MONTO_CRÉDITO':
            if ( Number(val.replace(/\s/g, '').replace('$', '')) > 5000000 || Number(val.replace(/\s/g, '').replace('$', '')) < 100000 ) {
                spanError.textContent = "Monto no podra ser mayor a 5 millones o menor a 100 mil pesos"
                return false
            }
            spanError.textContent = "";
            return true
            
    
        default:
            return true;
    }
    
}