import  { createContext, useState, useContext } from 'react'
import authApi from '../api/authApi.js'
import formApi from '../api/formApi.js'

// Lista de usuarios permitidos
const ALLOWED_USERS = [
    'miguelmhz1905@gmail.com',
    'emanuel.inserra@nuevoleon.gob.mx',
]

const AuthContext = createContext()


export const useAuth = () => {

    const auth = useContext(AuthContext)
    return auth
}

export const AuthProvider = ({children}) =>{
    const [isAuthenticated, setIsAuthenticated] = useState(()=>{
        const token = localStorage.getItem('impulso-token')
        return !!token

    })
    const [user, setUser] = useState(()=>{
        const user = localStorage.getItem('impulso-user')
        if (user) return JSON.parse(user)
        else return {}
    })

    

    const login = async(email, password)=> {
        try {
            // Verificar si el email está en la lista de usuarios permitidos
            if (ALLOWED_USERS.length > 0 && !ALLOWED_USERS.includes(email)) {
                return {
                    error: true,
                    msg: ''
                }
            }

            const {data} = await authApi.post('/login', { email:email, password: password})
            
            if (data && !data.error) {

                localStorage.setItem('impulso-token', data.token)
                localStorage.setItem('impulso-user', JSON.stringify(data.user))
                setUser(data.user)
                setIsAuthenticated(true)
                
            }else{
                localStorage.removeItem('impulso-token')
                localStorage.removeItem('impulso-user')
                setUser({})
                setIsAuthenticated(false)
            }
            return data
            
        } catch (error) {
            localStorage.removeItem('impulso-token')
            setUser({})
            setIsAuthenticated(false)
            console.log(error)
            if (error.response) {
                return error.response.data
            }else{
                return {
                    error: true,
                    msg: "Servidor desconectado"
                }
            }
        }
    }
    const register = async(email, password, name)=> {
        try {
            const {data} = await authApi.post('/sign-up', { email:email, password: password, name})
            console.log(data)
            if (data) {
                if (data.error) {
                    return data
                    
                }
                return data
            }
            
        } catch (error) {
            console.log(error)
            localStorage.removeItem('impulso-token')
            setUser({})
            setIsAuthenticated(false)
            if (error.response) {
                return error.response.data
            }else{
                return {
                    error: true,
                    msg: "Servidor desconectado"
                }
            }
        }
    }

    
    const logout = ()=>{
        setIsAuthenticated(false)
        localStorage.removeItem('impulso-token')
        localStorage.removeItem('impulso-user')
        setUser({})
    }
    const validateSession = async()=>{
        
        try {
            const {data} = await authApi.get('/validate-session');
            if (data && !data.error) {
                localStorage.setItem('impulso-token', data.token)
                localStorage.setItem('impulso-user', JSON.stringify(data.user))
            }
            if (ALLOWED_USERS.length > 0 && !ALLOWED_USERS.includes(data.user.email)) {
                logout()
            }
        } catch (error) {
            console.log(error)
            logout()
        }
    }
    const validateForm = async(id_form)=>{
        try {
            const {data} = await formApi.get('/validate/'+id_form);
            if (data && data.isValid) {
                return true
            }else{
                return false
            }
        } catch (error) {
            console.log(error)
            return false
        }
    }
    
    return (
        <AuthContext.Provider value={{isAuthenticated, login, logout, register, user, validateSession, validateForm}}>
            {children}
        </AuthContext.Provider>
    )
}
