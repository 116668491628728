import React, { useState } from 'react'
import Swal from 'sweetalert2'
import contactApi from '../api/contactApi'
import '../assets/css/contacto.css'
import { Navbar } from '../components/Navbar'
import { useAuth } from '../hooks/useAuth'



export const Contact = () => {
    const {user }= useAuth()
    const INITIAL_STATE = {name: user.name || "", email: user.email ||  "", subject:"", msg:""}
    const [form, setForm] = useState(INITIAL_STATE)
    const [msg, setMsg] = useState("")
  const handleChange = async(e) => {
    setForm({...form, [e.target.name]: e.target.value})
  }
  const handleSubmit = async(e) => {
    e.preventDefault()
    console.log(form)
    if (!form.email || !form.name) {
        setForm({...form, name: INITIAL_STATE.name, email: INITIAL_STATE.email})
    }else if(!form.msg){
        setMsg("El mensaje es obligatorio")
        return
    }else if (!form.subject){
        setMsg("El asunto es obligatorio")
        return
    }
    try {
        const {data} = await contactApi.post('/', form);
        if (data && !data.error) {
            Swal.fire({
                title: "Listo",
                text:"Mensaje enviado correctamente",
                icon: 'success',
                timer: 2000,
            })
            setForm(INITIAL_STATE)
        }else{

        }
    } catch (error) {
        Swal.fire({
            title: "Error",
            text:"Erro al enviar mensaje, intente más tarde o pongase en contacto con el administrador",
            icon: 'error',
            timer: 2000,
        })
    }
    
  }
    return (
    <>  
        <Navbar />
         <div className="mb-5">
        <div className="contact-header">
            <div className="title">
                <p>¿Tienes alguna duda? </p>
                <h1>¡Contactanos!</h1>
            </div>
            <div className="subtitle">
                <h2>Todos nuestros trámites son</h2>
                <h1>GRATUITOS</h1>
            </div>
        </div>
        <div className="contact-info w-full">
            <div className="contact-form  w-full">
                <form  onSubmit={handleSubmit}>
                    <div className="inputfield">
                        <label htmlFor="name"><i className="fas fa-user"></i></label>
                        <input onChange={handleChange} value={form.name} id="name" name="name" type="text" placeholder="Nombre" />
                    </div>
                    <div className="inputfield">
                        <label htmlFor="email"><i className="fas fa-envelope"></i> </label>
                        <input  onChange={handleChange} value={form.email} type="email" name="email" id="email" placeholder="Correo electronico" />
                        
                    </div>
                    <div className="inputfield">
                        <label htmlFor="subject"><i className="fas fa-sticky-note"></i> </label>
                        <input  onChange={handleChange} value={form.subject} type="text" id="subject" name="subject" placeholder="Asunto" />
                    </div>
                    <div className="inputfield">
                        <label htmlFor="msg"><i className="fas fa-comment-dots"></i></label>
                        <textarea value={form.msg} onChange={handleChange} name="msg" id="msg" cols="30" rows="10" placeholder="Tu mensaje aquí"></textarea>
                    </div>
                    
                    <div className="inputfield btn-field">
                        <button type="submit" className="btn btn-primary">Enviar</button>
                    </div>

                </form>
            </div>
        </div>

    </div>
    </>
  )
}
